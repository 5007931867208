import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'

export function PendingActivation() {
  const intl = useIntl()

  return (
    <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
      <div className='pending-activation-container d-flex flex-column align-items-center bg-white w-100 rounded shadow-sm p-15 gap-10'>
        <div className='text-center'>
          <h1 className='pending-activation-title m-0 fw-bolder lh-base'>
            {intl.formatMessage({id: 'ACTIVATE_ACCOUNT'})}
          </h1>
        </div>
        <div className='pending-activation-alert-text d-flex m-0 rounded p-3'>
          <div>{intl.formatMessage({id: 'ACCOUNT_ACTIVATION_INSTRUCTION'})}</div>
        </div>

        <Link to='/auth/login' className='link-primary w-100'>
          <button
            type='submit'
            id='gori_sign_in_submit'
            className='pending-activation-button d-block w-100 text-white fs-5 fw-500 btn btn-lg rounded px-3 py-4 mx-auto'
          >
            {intl.formatMessage({id: 'LOGIN'})}
          </button>
        </Link>
      </div>
    </CSSTransition>
  )
}
