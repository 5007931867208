import moment from 'moment'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../_gori/helpers'
import {useAuth} from '../../auth'

const MNDA: React.FC = () => {
  const intl = useIntl()
  const {currentAccount} = useAuth()

  return (
    <>
      <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={{
          backgroundImage: `url(${toAbsoluteUrl('/media/gori/login/gori_bg_rocket_1.webp')})`,
        }}
      >
        <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/big-shipbae-logo.png')}
            className='h-60px'
          />
          <div className='w-50 fs-3'>
            <div>
              <div className='fw-bolder text-center my-12'>
                <h1>{intl.formatMessage({id: 'GORI_COMPANY_LLC'})}</h1>
                <h1 className='mt-8'>
                  {intl.formatMessage({id: 'MUTUAL_NON_DISCLOSURE_AGREEMENT'})}
                </h1>
              </div>
              <div>
                {intl.formatMessage(
                  {id: 'MUTUAL_NON_DISCLOSURE_AGREEMENT_CONTENT'},
                  {
                    agreement: <strong>("{intl.formatMessage({id: 'AGREEMENT'})}")</strong>,
                    effectiveDate: <strong>{moment().format('MM/DD/YYYY')}</strong>,
                    gori: <strong>{intl.formatMessage({id: 'GORI_COMPANY_LLC'})}</strong>,
                    companyName: <strong>{currentAccount?.name}</strong>,
                  }
                )}
              </div>
              <div className='my-10'>
                {intl.formatMessage(
                  {id: 'MUTUAL_NON_DISCLOSURE_AGREEMENT_CONTENT_1'},
                  {
                    confidentialInfoStart: (
                      <strong>{intl.formatMessage({id: 'CONFIDENTIAL_INFORMATION'})}</strong>
                    ),
                    confidentialInfo: (
                      <strong>"{intl.formatMessage({id: 'CONFIDENTIAL_INFORMATION'})}"</strong>
                    ),
                    disclosingParty: (
                      <strong>("{intl.formatMessage({id: 'DISCLOSING_PARTY'})}")</strong>
                    ),
                    receivingParty: (
                      <strong>("{intl.formatMessage({id: 'RECEIVING_PARTY'})}")</strong>
                    ),
                    representative: <strong>"{intl.formatMessage({id: 'REPRESENTATIVE'})}"</strong>,
                  }
                )}
              </div>
              <div className='my-10'>
                {intl.formatMessage(
                  {id: 'MUTUAL_NON_DISCLOSURE_AGREEMENT_CONTENT_2'},
                  {
                    exceptions: (
                      <strong className='text-decoration-underline'>
                        {intl.formatMessage({id: 'EXCEPTIONS'})}
                      </strong>
                    ),
                    section_3: (
                      <span className='text-decoration-underline'>
                        {intl.formatMessage({id: 'SECTION_3'})}
                      </span>
                    ),
                  }
                )}
              </div>
              <div className='my-10'>
                {intl.formatMessage(
                  {id: 'MUTUAL_NON_DISCLOSURE_AGREEMENT_CONTENT_3'},
                  {
                    confidentiality: (
                      <strong className='text-decoration-underline'>
                        {intl.formatMessage({id: 'CONFIDENTIALITY'})}
                      </strong>
                    ),
                  }
                )}
              </div>
              <div className='my-10'>
                {intl.formatMessage(
                  {id: 'MUTUAL_NON_DISCLOSURE_AGREEMENT_CONTENT_4'},
                  {
                    compelled_disclosure: (
                      <strong className='text-decoration-underline'>
                        {intl.formatMessage({id: 'COMPELLED_DISCLOSURE'})}
                      </strong>
                    ),
                  }
                )}
              </div>
              <div className='my-10'>
                {intl.formatMessage(
                  {id: 'MUTUAL_NON_DISCLOSURE_AGREEMENT_CONTENT_5'},
                  {
                    ownership_no_warranties: (
                      <strong className='text-decoration-underline'>
                        {intl.formatMessage({id: 'OWNERSHIP_NO_WARRANTIES'})}
                      </strong>
                    ),
                  }
                )}
              </div>
              <div className='my-10'>
                {intl.formatMessage(
                  {id: 'MUTUAL_NON_DISCLOSURE_AGREEMENT_CONTENT_6'},
                  {
                    specific_performance: (
                      <strong className='text-decoration-underline'>
                        {intl.formatMessage({id: 'SPECIFIC_PERFORMANCE'})}
                      </strong>
                    ),
                  }
                )}
              </div>
              <div className='my-10'>
                {intl.formatMessage(
                  {id: 'MUTUAL_NON_DISCLOSURE_AGREEMENT_CONTENT_7'},
                  {
                    term_and_termination: (
                      <strong className='text-decoration-underline'>
                        {intl.formatMessage({id: 'TERM_AND_TERMINATION'})}
                      </strong>
                    ),
                  }
                )}
              </div>
              <div className='my-10'>
                <div>
                  {intl.formatMessage(
                    {id: 'RETURN_DESTRUCTION'},
                    {
                      return_destruction: (
                        <strong className='text-decoration-underline'>
                          {intl.formatMessage({
                            id: 'RETURN_OR_DESTRUCTION_OF_CONFIDENTIAL_INFORMATION',
                          })}
                        </strong>
                      ),
                    }
                  )}
                </div>
                {intl.formatMessage({id: 'MUTUAL_NON_DISCLOSURE_AGREEMENT_CONTENT_8'})}
              </div>
              <div className='my-10'>
                {intl.formatMessage(
                  {id: 'MUTUAL_NON_DISCLOSURE_AGREEMENT_CONTENT_9'},
                  {
                    general: (
                      <strong className='text-decoration-underline'>
                        {intl.formatMessage({id: 'GENERAL'})}
                      </strong>
                    ),
                  }
                )}
              </div>
              <div className='my-10'>
                <strong>{intl.formatMessage({id: 'IN_WITNESS_WHEREOF'})}</strong>,{' '}
                {intl.formatMessage({
                  id: 'THE_PARTIES_HERETO_HAVE_EXECUTED_THIS_MUTUAL_NON_DISCLOSURE_AGREEMENT_BY_THEIR_DULY_AUTHORIZED_OFFICERS_OR_REPRESENTATIVES',
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {MNDA}
